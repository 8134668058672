.input {
    width: 100%;
    padding: 7px 12px;
    margin: 0 0 0 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.9rem;
  }
  
.input > .submit {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
  
.input > .submit :hover {
  background-color: #45a049;
}
  
.search {
  border: 1px solid grey;
  border-radius: 5px;
  height: 20px;
  width: 100%;
  padding: 2px 23px 2px 30px;
  outline: 0;
  background-color: #f5f5f5;
}
  
.search:hover,
.search:focus {
  border: 1.5px solid #009688;
  background-color: white;
}
  
  
  