:root {
    --primary: #0053c5;
    --light: #808080;
    --dark: #212121;
    --white: #fff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lato, Helvetica, sans-serif;
}

.navbar-header {
    padding-bottom: 82px;
}
.navbar-container {
    max-width: 1280px;
    /* To center navbar on bigger screens */
    margin: 0 auto;
    
    display: flex;
    align-items: center;
    /* Makes everything sit in a line */
    justify-content: space-between;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background-color: var(--white);
    padding: 16px 32px;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.hamburger {
    display: none;
    position: relative;
    z-index: 1;
    user-select: none;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.hamburger span {
    display: block;
    width: 33px;
    height:4px;
    margin-bottom: 5px;
    position: relative;
    background-color: var(--light);
    border-radius: 6px;
    z-index: 1;
    transform-origin: 0 0;
    transition: 0.4s;
}

.hamburger:hover span:nth-child(2) {
    /* Make middle hamburger menu move to the right and change color */
    transform: translateX(10px);
    background-color: var(--primary);
}

.hamburger.is-active span:nth-child(1) {
    transform: translate(0px, -2px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
    /* remove middle hamburger bar to complete the x */
    opacity: 0;
    transform: translateX(15px);
}

.hamburger.is-active span:nth-child(3) {
    /* make an x */
    transform: translate(-3px, 3px) rotate(-45deg);

}

.hamburger.is-active:hover span {
    background-color: var(--primary);
}

.menu {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 0%;
    width: 100%;
}

.filters-container {
    display: flex;
    flex-direction: row;
    /* Centers the filter container between the logo and the navlink */
    margin-left: auto;
    margin-right: auto;
}

.filter-item {
    margin: 3px;
}

.navbar-link-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.navbar-link {
    display: flex;
    flex-direction: row;
    color: #808080;
    text-decoration: none;
    justify-content: space-between;
    margin: 6px;
}

.navbar-link:hover {
    text-decoration: none;
    color: var(--primary);
}

.navbar-link.active {
    text-decoration: none;
    color: #0053c5;
    font-weight: 600;
}

.navbar-link-icon.active {
    stroke: red;
}

.nav-no-suites-found {
    display: flex;
    margin-left: 3px;
    margin-top: 20px;
    font-size: 10pt;
}


/* For small screens */
@media (max-width: 768px) {
    /* Show hamburger menu only for small screens */
    .hamburger {
        display: block;
    }

    .menu {
        display: flex;
        position: fixed;
        left: 100%;
        top: 82px;
        width: 100%;
        z-index: 1;
        background-color: var(--white);
        transition: 0.4s;
        border-top: 0.1px solid rgba(0,0,0,.2);
        box-shadow: 2px 2px 2px -2px rgba(0,0,0,.2);
        justify-content: center;
        flex-direction: column;
    }

    .menu.is-active {
        left: 0%;
    }

    .filters-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .navbar-link-container {
        margin-right: auto;
        flex-direction: column;
        text-align: center;
    }

    /* Center the college dropdown filter item */
    .basic-single {
        margin-left: auto;
        margin-right: auto;
    }

    .navbar-link {
        padding: 5px 16px;
        align-self: center;
    }

    .nav-no-suites-found {
        margin: 0 auto;
    }
}