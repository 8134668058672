img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}
  
.hero-container {
    background-image: none;
    background-position: fixed;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    flex-direction: column;
    align-items: left;
    padding-left: 5%;
    padding-top: 5%;
}

.hero-container > h1 {
    text-align: left;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: 800;
    font-size: 3.7vw;
    color: #0053c5;
    padding-bottom: 18px;
    width: 80%;
}

.hero-container > p {
    text-align: left;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: 600;
    width: 50%;
    color: #000;
    font-size: 1.5vw;
    padding-bottom: 8px;
}

.hero-btns {
    margin-top: 32px;
    align-items: left;
    justify-content: left;
}

.hero-btns .btn {
    margin: 6px;
    font-size: 56pt;
}

.fa-play-circle {
    margin-left: 4px;
}

.lg-screen-button {
    display: flex;
    justify-content: left;
    max-height: 60px;
    border-radius: 8px;
}

@media (min-width: 768px) {
    .hero-container {
        background-image: url("../../static/landing_page.png");
    }

    .mobile-para {
        display: none;
    }

    .sm-screen-buttons {
        display: none;
    }
}

/* For small screens */
@media (max-width: 768px) {
    .hero-container {
        padding: 10px 0 0 0;
        margin: 0 auto;
    }

    .hero-container h1 {
        width: 100%;
        font-size: 27px;
        text-align: center;
    }

    .hero-container p {
        display: none;
    }

    /* use mobile hero container */
    .mobile-para {
        font-family: Lato, Helvetica, sans-serif;
        font-weight: 600;
        color: #000;
        font-size: 15px;
        padding-bottom: 8px;
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .lg-screen-button {
        display: none;
    }

    .sm-screen-buttons {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}


