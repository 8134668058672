/* Clear floats after the columns */
.row {
    content: "";
    display: flex;
    flex-flow: row wrap;
    margin-top: 2%;
    /* Then we define how is distributed the remaining space */
    justify-content: space-around; 
    clear: both;
    padding-left: 10%;
    padding-right: 10%;
}

/* Create three equal columns that floats next to each other */
.column {
    width: 30%;
    height: 25%;
    border-radius: 10pt;
    padding: 2vw;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
    margin: 4px 2px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-color: #fff;
    box-shadow: 0 6px 15px rgba(56, 125, 255, 0.07); 
}

.hero-secondary {
    text-align: center;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: 800;
    margin-top: 5%;
    width: 100%;
    font-size:3vw;
    color: #0053C5; 
    padding-top: 18px;
    padding-bottom: 18px;
}

.column-image-container{
    height: 9vw;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
}

.column-image {
    height: 8vw;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    margin-top: 5%;
    position: relative;
}  

.column-title {
    text-align: center;
    font-family: Lato, Helvetica, sans-serif;
    font-weight: 800;
    color: #000;
    font-size: 1.5vw;
}

/* For small screens */
@media (max-width: 768px) {
    .about-hero-section-container {
        display: flex;
        flex-direction: column;
        height: 100vh;

    }
    .column {
        width: 90%;
        height: 40%;
        padding: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .hero-secondary {
        font-size: 20px;
    }

    .column-title {
        font-size: 12px;
        margin-bottom: 0;
    }

    .column-image-container{
        height: 100px;
        margin-bottom: 0;
    }
    
    .column-image {
        height: 80%;
        object-fit: contain;
        margin-right: auto;
        z-index: 2;
        margin-top: 5%;
        position: relative;
    }  
    
}

  
  
  